import dayjs from "dayjs";
import { ALL_MONTHS } from "../consts";
import { formatDate } from "./formatDate";

export const getFiscalYear = (months: string[], currentFiscalYear: string) => {
    const allMonths = [...ALL_MONTHS];
    const startYear = Number(currentFiscalYear.substring(0, 4));
    const startMonthIndex = allMonths.indexOf(months[0]);
    const endMonthIndex = allMonths.indexOf(months[1]);

    const endYear = startMonthIndex < endMonthIndex ? startYear : startYear + 1;

    return {
        fromDate: formatDate(startYear, startMonthIndex),
        endDate: dayjs(formatDate(endYear, endMonthIndex))
            .endOf("month")
            .format("YYYY-MM-DD"),
    };
};