import {
  Box,
  Button,
  Dialog,
  InputLabel
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";

import { AdaptedCollectorsMissingDocuments, postMissingDocuments } from "api";

import {
  COLORS,
  H6,
  Loading,
  MultiSelectControlledOption,
  Pagination,
  ROWS_PER_PAGE_OPTIONS,
  usePagination,
} from "components";
import { EsgMultiselectControlledField } from "components/inputs/esg-multi-select-field";

import {
  useGetAllowedValues,
  useGetConfigParameter,
  useSites,
  useUtilityBillsYears,
} from "hooks";

import { LOCAL_STORAGE_KEYS } from "consts";

import { AddBillModal, CollectorMonthView, DataBoxes, MonthCell, SelectField } from "./components";
import { ALL_MONTHS, DATA_BOXES, MISSING_DOCUMENTS_STATIC_COLUMNS } from "./consts";
import { checkDaysMatchMonthLength, completeMonths, getFiscalYear } from "./helpers";

export const MissingDocumentsPage = () => {
  const { data: configParameter } = useGetConfigParameter();
  const { data: availableYears } = useUtilityBillsYears();
  const { data: allowedValues } = useGetAllowedValues();
  const { data: sitesData } = useSites();
  const dataBoxes = [...DATA_BOXES];
  const allMonths = [...ALL_MONTHS];

  const {
    mutateAsync: postMissingDocumentsRequest,
    isLoading: loadingMissingDocuments,
  } = useMutation(postMissingDocuments);

  const companyMonths = configParameter
    ?.filter((param: any) => param.name.includes("company_month"))
    .map((param: any) => param.configValue) ?? ["Jan", "Dec"];

  const allFiscalYears: string[] = useMemo(
    () =>
      (availableYears &&
        availableYears.map((fy: number) => `${fy} Fiscal Year`)) || [
        `${dayjs().get("year")} Fiscal Year`,
      ],
    [availableYears],
  );
  const countries = useMemo(
    () =>
      allowedValues
        ? allowedValues.countries.sort().map((coun: string) => ({
          name: coun.replaceAll("_", " "),
          label: coun,
          value: coun,
        }))
        : [],
    [allowedValues],
  );
  const utilityTypes = useMemo(
    () =>
      allowedValues
        ? allowedValues.utilityTypes
          .sort((utA: any, utB: any) => (utA.name > utB.name ? 1 : -1))
          .map((ut: any) => ({ name: ut.name, label: ut.name, value: ut.id }))
        : [],
    [allowedValues],
  );
  const sites = useMemo(
    () =>
      sitesData
        ? sitesData.map((site: any) => ({
          name: site.name,
          label: site.name,
          value: site.id,
        }))
        : [],
    [sitesData],
  );
  const businessUnits = useMemo(
    () =>
      allowedValues
        ? allowedValues.businessUnits
          .sort()
          .map((bu: string) => ({ name: bu, label: bu, value: bu }))
        : [],
    [allowedValues],
  );
  const countryRegions = useMemo(
    () =>
      allowedValues
        ? allowedValues.countryRegions
          .sort()
          .map((cr: string) => ({ name: cr, label: cr, value: cr }))
        : [],
    [allowedValues],
  );
  const states = ["Historical", "Active", "Partially Active", "Inactive"].map(
    (item: string) => ({ name: item, label: item, value: item }),
  );
  const sitesByCountry = useMemo(
    () =>
      sitesData
        ? sitesData.reduce((acc: any, site: any) => {
          if (!acc[site.country]) {
            acc[site.country] = [];
          }
          acc[site.country].push(site.id);
          return acc;
        }, [])
        : [],
    [sitesData],
  );

  const { pageSize, pageNumber, onPageSizeChange, onPageChange } =
    usePagination({
      pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
    });

  const [allMissingData, setAllMissingData] = useState<any[]>([]);
  const [currentMissingData, setCurrentMissingData] = useState<any[]>([]);
  const [currentFY, setCurrentFY] = useState<string>(
    allFiscalYears[allFiscalYears.length - 1],
  );
  const [monthHeaderFY, setMonthHeaderFY] = useState<string>(
    currentFY.substring(0, 4),
  );
  const [isActivatedMissing, setIsActivatedMissing] = useState<boolean>(false);
  const [currentCountries, setCurrentCountries] = useState<
    MultiSelectControlledOption[]
  >([]);
  const [currentSiteIds, setCurrentSiteIds] = useState<
    MultiSelectControlledOption[]
  >([]);
  const [currentUtilityTypeIds, setCurrentUtilityTypeIds] = useState<
    MultiSelectControlledOption[]
  >([]);
  const [currentBusinessUnits, setCurrentBusinessUnits] = useState<
    MultiSelectControlledOption[]
  >([]);
  const [currentCountryRegions, setCurrentCountryRegions] = useState<
    MultiSelectControlledOption[]
  >([]);
  const [currentStates, setCurrentStates] = useState<
    MultiSelectControlledOption[]
  >([]);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [enableSearch, setEnableSeach] = useState<boolean>(false);
  const [isSitesFiltered, setIsSitesFiltered] = useState<boolean>(false);
  const [monthSelected, setMonthSelected] = useState<string>("");
  const [collectorMissingDocuments, setCollectorMissingDocuments] =
    useState<AdaptedCollectorsMissingDocuments>();
  const [openMonthView, setOpenMonthView] = useState<boolean>(false);
  const [loadingOnlyMissingDocuments, setLoadingOnlyMissingDocuments] =
    useState<boolean>(false);
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [selectedCollectorId, setSelectedCollectorId] = useState<number | null>(null);

  const [defaultObject, setDefaultObject] = useState<any>({
    siteIds: sites.map((site: any) => site.value),
    utilityTypeIds: null,
    fromDate: getFiscalYear(companyMonths, currentFY).fromDate,
    endDate: getFiscalYear(companyMonths, currentFY).endDate,
    businessUnits: null,
    countryRegions: null,
  });

  const monthHeaders = completeMonths(
    companyMonths,
    Number(monthHeaderFY),
  ) as any;

  const monthColumns = monthHeaders.map((monthValue: string) => ({
    id: `${monthHeaderFY}-${allMonths.indexOf(monthValue.substring(0, 3)) + 1}`,
    header: monthValue,
    accessorKey: `months[${monthValue.substring(0, 3)}]`,
    Cell: ({ cell, row }: { cell: any; row: any }) => {
      const { collector, months } = row.original;
      const unit = collector.consumptionUnit;
      const handleMonthSelectedOpen = () => {
        setMonthSelected(monthValue.substring(0, 3));
        setCollectorMissingDocuments({
          unit,
          id: collector.id,
          collector,
          days: allMonths.reduce((acc: any, month: string) => {
            const monthIndex = allMonths.indexOf(month) + 1;
            if (!acc[monthIndex]) {
              acc[monthIndex] = months[month]
                ? months[month].days.reduce(
                  (dayAcc: any, currentDay: any) => ({
                    ...dayAcc,
                    [currentDay.day]: currentDay.usage ?? null,
                  }),
                  {},
                )
                : null;
            }
            return acc;
          }, {}),

          months: allMonths.reduce((acc: any, month: string) => {
            if (!acc[allMonths.indexOf(month) + 1]) {
              acc[allMonths.indexOf(month) + 1] = {
                usage: months[month] ? months[month].monthUsage : null,
                billType: months[month] ? months[month].billType : null,
              };
            }
            return acc;
          }, {}),
        });
        setOpenMonthView(true);
      };

      return (
        <MonthCell
          cell={cell}
          handleBillModalOpen={handleBillModalOpen}
          handleMonthSelectedOpen={handleMonthSelectedOpen}
          isMonthModalOpen={openMonthView}
        />
      );
    },
    enableSorting: false,
    minSize: 120,
    size: 120,
  }));

  const MISSING_DOCUMENTS_COLUMNS = [...MISSING_DOCUMENTS_STATIC_COLUMNS, ...monthColumns];

  const handleUpdateMissingDocuments = async () => {
    await postMissingDocumentsRequest(defaultObject, {
      onSuccess: (response: any) => {
        const { data } = response;
        const parsedData = data.reduce(
          (acc: any, currentCol: any, index: number) => {
            const { collector, unit, days } = currentCol;
            acc[index] = {
              collector,
              unit,
              months: days.reduce((monthAcc: any, date: any) => {
                const currentMonth =
                  allMonths[dayjs(date.day, "YYYY-MM-DD").month()];
                const updatedMonthAcc = { ...monthAcc };

                if (!updatedMonthAcc[currentMonth]) {
                  updatedMonthAcc[currentMonth] = {};
                }

                const currentMonthData = days.filter(
                  (day: any) =>
                    dayjs(day.day, "YYYY-MM-DD").month() ===
                    allMonths.indexOf(currentMonth),
                );

                updatedMonthAcc[currentMonth] = {
                  days: currentMonthData.map(
                    ({ day, usage }: { day: string; usage: number }) => ({
                      day: day.substring(8),
                      usage,
                    }),
                  ),
                  billType: Array.from(
                    new Set(currentMonthData.map((day: any) => day.billType)),
                  )[0],
                  monthUsage: currentMonthData.reduce(
                    (usageAcc: number, day: any) => usageAcc + day.usage,
                    0,
                  ),
                };
                return updatedMonthAcc;
              }, {}),
              state: (() => {
                const {
                  accountNumber,
                  meterNumber,
                  startDate,
                  endDate,
                  disableDate,
                } = collector;
                const fiscalYear = currentFY.substring(0, 4);
                const fromDate =
                  startDate === null
                    ? true /* same as 1970 */
                    : dayjs(startDate) <=
                    dayjs(
                      `${fiscalYear}-${allMonths.indexOf(companyMonths[0]) + 1
                      }`,
                    );
                const toDate =
                  (endDate !== null || disableDate !== null) &&
                  dayjs(endDate ?? disableDate) >=
                  dayjs(
                    `${fiscalYear}-${allMonths.indexOf(companyMonths[1]) + 1
                    }`,
                  ) &&
                  dayjs(endDate ?? disableDate)
                    .get("year")
                    .toString() !== fiscalYear;
                const isHistorical = !accountNumber && !meterNumber;
                const isActive = fromDate && toDate;
                const isInactive =
                  startDate === null ||
                  (dayjs(startDate) <=
                    dayjs(
                      `${fiscalYear}-${allMonths.indexOf(companyMonths[0]) + 1
                      }`,
                    ) &&
                    dayjs(endDate ?? disableDate) <=
                    dayjs(
                      `${fiscalYear}-${allMonths.indexOf(companyMonths[0]) + 1
                      }`,
                    )) ||
                  (startDate !== null &&
                    dayjs(startDate) >=
                    dayjs(
                      `${fiscalYear}-${allMonths.indexOf(companyMonths[1]) + 1
                      }`,
                    ) &&
                    dayjs(endDate ?? disableDate) >=
                    dayjs(
                      `${fiscalYear}-${allMonths.indexOf(companyMonths[0]) + 1
                      }`,
                    ));

                return isHistorical
                  ? "Historical"
                  : isActive
                    ? "Active"
                    : isInactive
                      ? "Inactive"
                      : "Partially Active";
              })(),
            };
            return acc;
          },
          [],
        );
        setMonthHeaderFY(currentFY.substring(0, 4));
        if (!currentStates.length) {
          setAllMissingData(parsedData);
          setCurrentMissingData(parsedData);
        } else {
          const filteredData = parsedData.filter((item: any) =>
            currentStates.map((state: any) => state.value).includes(item.state),
          );
          setAllMissingData(filteredData);
          setCurrentMissingData(filteredData);
        }
      },
      onError: () => {
        // implement error handling
      },
    });
    setSearchActive(false);
  };

  const handleActivateMissing = () => {
    setLoadingOnlyMissingDocuments(true);
    setTimeout(() => {
      const hasMissingData = allMissingData.filter(({ months, state }) => {
        if (state !== "Active") return false;
        const areAllMonthsIncluded = Object.keys(months).length === 12;
        if (!areAllMonthsIncluded) return true;

        const hasIncompleteMonth = Object.entries(months).some((month: any) => {
          const monthIndex = allMonths.indexOf(month[0]) + 1;

          const hasEnoughDays = checkDaysMatchMonthLength(
            month[1].days.length,
            monthIndex,
            Number(monthHeaderFY),
          );

          return !hasEnoughDays;
        });

        return hasIncompleteMonth;
      });
      setCurrentMissingData(hasMissingData);
      setIsActivatedMissing(true);
      setLoadingOnlyMissingDocuments(false);
    }, 3000);
  };

  const handleShowAll = () => {
    setLoadingOnlyMissingDocuments(true);
    setTimeout(() => {
      setCurrentMissingData(allMissingData);
      setIsActivatedMissing(false);
      setLoadingOnlyMissingDocuments(false);
    }, 3000);
  };

  const handleClearFilters = () => {
    setCurrentFY(allFiscalYears[allFiscalYears.length - 1]);
    setCurrentCountries([]);
    setCurrentSiteIds([]);
    setCurrentUtilityTypeIds([]);
    setCurrentBusinessUnits([]);
    setCurrentCountryRegions([]);
    setCurrentStates([]);
    setDefaultObject({
      siteIds: sites.map((site: any) => site.value),
      utilityTypeIds: null,
      fromDate: getFiscalYear(
        companyMonths,
        allFiscalYears[allFiscalYears.length - 1],
      ).fromDate,
      toDate: getFiscalYear(
        companyMonths,
        allFiscalYears[allFiscalYears.length - 1],
      ).endDate,
      businessUnits: null,
      countryRegions: null,
    });
    setSearchActive(true);
  };

  const updateArr = (
    arr: any[],
    value: string | number,
    unchecked?: boolean,
    isCountrySelection: boolean = false,
  ) => {
    const currentSelection = Array.from(
      new Set([...arr.map((item: any) => item.value), value]),
    );
    if (isCountrySelection) {
      setIsSitesFiltered(true);
      if (unchecked) {
        const filteredSelection = arr
          .filter((item: any) => item.value !== value)
          .map((item: any) => item.value);
        if (!filteredSelection.length) {
          setIsSitesFiltered(false);
          return null;
        }
        return filteredSelection;
      }
      if (!(currentSelection.includes(value) && !unchecked)) {
        const countriesSelected = currentSelection.filter(
          (country: any) => country !== value,
        );
        const idsByCountriesSelected = countriesSelected
          .map((country: any) => sitesByCountry[country])
          .flat()
          .filter((site: any) => site !== undefined);
        return idsByCountriesSelected;
      }
      const idsByCountriesSelected = currentSelection
        .map((country: any) => sitesByCountry[country])
        .flat()
        .filter((site: any) => site !== undefined);
      return idsByCountriesSelected;
    }
    if (arr.find((item: any) => item.value === "ALL")) {
      if (isCountrySelection) setIsSitesFiltered(false);
      return null;
    }
    if (unchecked) {
      const filteredSelection = arr
        .filter((item: any) => item.value !== value)
        .map((item: any) => item.value);
      if (!filteredSelection.length) return null;
      return filteredSelection;
    }
    return currentSelection.includes("ALL") ? null : currentSelection;
  };

  const getSitesFiltered = () =>
    sitesData
      ?.filter((site: any) =>
        currentCountries
          .map((country: any) => country.value)
          .flat()
          .includes(site.country),
      )
      .map((site: any) => ({
        name: site.name,
        label: site.name,
        value: site.id,
      })) ?? [];

  const handleBillModalOpen = (id: number | null) => {
    setSelectedCollectorId(id);
    setIsBillModalOpen(true);
  }

  const closeBillModal = () => setIsBillModalOpen(false);

  useEffect(() => {
    setDefaultObject({
      siteIds: sites.map((site: any) => site.value),
      utilityTypeIds: null,
      fromDate: getFiscalYear(companyMonths, currentFY).fromDate,
      toDate: getFiscalYear(companyMonths, currentFY).endDate,
      businessUnits: null,
      countryRegions: null,
    });
    setEnableSeach(true);
  }, [sites]); // eslint-disable-line

  return (
    <Box sx={{ margin: "12px 24px" }}>
      <H6>Please select your filters and then search to see data below.</H6>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "200px",
              "& > div:first-of-type": {
                height: "35px",
              },
            }}
          >
            <InputLabel
              sx={{
                fontSize: "12px",
                color: "black",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              Fiscal Year
            </InputLabel>
            <SelectField
              options={allFiscalYears}
              initialValue={currentFY}
              onChangeFn={(event: string) => {
                setCurrentFY(event);
                setDefaultObject({
                  ...defaultObject,
                  fromDate: getFiscalYear(companyMonths, event.substring(0, 4))
                    .fromDate,
                  toDate: getFiscalYear(companyMonths, event.substring(0, 4))
                    .endDate,
                });
                setSearchActive(true);
              }}
              placeholder="Select Fiscal Year"
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <EsgMultiselectControlledField
              label="Country"
              selectedValues={currentCountries}
              setSelectedValues={setCurrentCountries}
              options={countries}
              type="checkMissing"
              isAllSelectedOnEmptySelection
              onchangeFn={(
                value: string | number,
                unchecked: boolean | undefined,
              ) => {
                setDefaultObject({
                  ...defaultObject,
                  siteIds:
                    updateArr(currentCountries, value, unchecked, true) ??
                    sites.map((site: any) => site.value),
                });
                setSearchActive(true);
              }}
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <EsgMultiselectControlledField
              label="Utility Type"
              selectedValues={currentUtilityTypeIds}
              setSelectedValues={setCurrentUtilityTypeIds}
              options={utilityTypes}
              type="checkMissing"
              isAllSelectedOnEmptySelection
              onchangeFn={(
                value: string | number,
                unchecked: boolean | undefined,
              ) => {
                setDefaultObject({
                  ...defaultObject,
                  utilityTypeIds: updateArr(
                    currentUtilityTypeIds,
                    value,
                    unchecked,
                  ),
                });
                setSearchActive(true);
              }}
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <EsgMultiselectControlledField
              label="Sites"
              selectedValues={currentSiteIds}
              setSelectedValues={setCurrentSiteIds}
              options={!isSitesFiltered ? sites : getSitesFiltered()}
              type="checkMissing"
              isAllSelectedOnEmptySelection
              onchangeFn={(
                value: string | number,
                unchecked: boolean | undefined,
              ) => {
                setDefaultObject({
                  ...defaultObject,
                  siteIds:
                    updateArr(currentSiteIds, value, unchecked) ??
                    sites.map((site: any) => site.value),
                });
                setSearchActive(true);
              }}
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <EsgMultiselectControlledField
              label="Business Unit"
              selectedValues={currentBusinessUnits}
              setSelectedValues={setCurrentBusinessUnits}
              options={businessUnits}
              type="checkMissing"
              isAllSelectedOnEmptySelection
              onchangeFn={(
                value: string | number,
                unchecked: boolean | undefined,
              ) => {
                setDefaultObject({
                  ...defaultObject,
                  businessUnits: updateArr(
                    currentBusinessUnits,
                    value,
                    unchecked,
                  ),
                });
                setSearchActive(true);
              }}
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <EsgMultiselectControlledField
              label="Region"
              selectedValues={currentCountryRegions}
              setSelectedValues={setCurrentCountryRegions}
              options={countryRegions}
              type="checkMissing"
              isAllSelectedOnEmptySelection
              onchangeFn={(
                value: string | number,
                unchecked: boolean | undefined,
              ) => {
                setDefaultObject({
                  ...defaultObject,
                  countryRegions: updateArr(
                    currentCountryRegions,
                    value,
                    unchecked,
                  ),
                });
                setSearchActive(true);
              }}
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <EsgMultiselectControlledField
              label="State"
              selectedValues={currentStates}
              setSelectedValues={setCurrentStates}
              options={states}
              type="checkMissing"
              isAllSelectedOnEmptySelection
              onchangeFn={() => setSearchActive(true)}
            />
          </Box>
          <Box
            sx={{
              width: "296px !important",
              display: "flex",
              gap: "16px",
              mt: "22px",
            }}
          >
            <Button
              sx={{
                width: "140px",
                height: "36px",
                borderRadius: "8px",
              }}
              disabled={!enableSearch}
              variant={searchActive ? "contained" : "outlined"}
              onClick={handleUpdateMissingDocuments}
            >
              Search
            </Button>
            <Button
              sx={{
                width: "140px",
                height: "36px",
                borderRadius: "8px",
              }}
              variant="outlined"
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: "fit-content",
            height: "42px",
            padding: "3px",
            border: `1px solid ${COLORS.Mainblue}`,
            borderRadius: "30px",
            marginTop: "16px",
          }}
        >
          <Button
            variant={isActivatedMissing ? "contained" : "text"}
            sx={{ borderRadius: "30px" }}
            onClick={handleActivateMissing}
          >
            Only Missing
          </Button>
          <Button
            variant={!isActivatedMissing ? "contained" : "text"}
            sx={{ borderRadius: "30px" }}
            onClick={handleShowAll}
          >
            Show all
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "32px",
          flexWrap: "wrap",
          marginTop: "48px",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {dataBoxes.map(({ color, title }) => <DataBoxes color={color} title={title} />)}
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "calc(100vw - 151px)",
          marginTop: "32px",
          border: "1px solid #C0D0D4",
          borderRadius: "7px",
          "& > div": {
            margin: 0,
            "& > div": {
              height: "calc(100vh - 440px)",
              maxHeight: "calc(100vh - 440px)",
              overflowY: "auto",
            },
          },
          "& table": {
            "& > thead": {
              position: "sticky",
              top: 0,
              zIndex: 2,
              "& > tr > th:nth-of-type(5)": {
                borderRight: "1px solid #054254",
              },
              "& > tr > th:nth-of-type(7)": {
                borderRight: "1px solid #054254",
              },
            },
            "& > tbody": {
              "& > tr > td:nth-of-type(5)": {
                borderRight: "1px solid #054254",
              },
              "& > tr > td:nth-of-type(7)": {
                borderRight: "1px solid #054254",
              },
            },
          },
        }}
      >
        {loadingMissingDocuments || loadingOnlyMissingDocuments ? (
          <Box
            sx={{
              "& > div: first-of-type": {
                marginTop: "100px",
                height: "auto",
              },
            }}
          >
            <Loading />
          </Box>
        ) : (
          <MaterialReactTable
            muiTableContainerProps={{
              sx: {
                border: "1px solid #C0D0D4",
                borderRadius: "7px",
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                borderRight: "1px solid #C0D0D4",
                color: "#054254",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                maxHeight: "60px",
                borderRight: "1px solid #C0D0D4",
                backgroundColor: "#fff",
                padding: 0,
                "& > div": {
                  maxHeight: "60px",
                },
              },
            }}
            columns={MISSING_DOCUMENTS_COLUMNS}
            data={currentMissingData}
            enableGlobalFilter={false}
            enablePagination
            enablePinning
            enableStickyHeader
            muiTablePaginationProps={{
              rowsPerPageOptions: [100, 500, 1000],
              showFirstButton: true,
              showLastButton: true,
            }}
            initialState={{
              pagination: { pageSize, pageIndex: pageNumber },
              columnPinning: {
                left: [
                  "collector.siteName",
                  "collector.providerName",
                  "collector.accountNumber",
                  "collector.meterNumber",
                  "state",
                ],
              },
            }}
          />
        )}
      </Box>
      {!(loadingMissingDocuments || loadingOnlyMissingDocuments) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Pagination
            page={pageNumber + 1}
            rowsCount={currentMissingData.length || 0}
            rowsPerPage={pageSize}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={onPageChange}
            onRowsPerPageChange={onPageSizeChange}
          />
        </Box>
      )}
      {openMonthView && (
        <div>
          <Dialog
            open
            sx={{
              "& > div > div": {
                width: "1480px",
                minWidth: "1480px",
                maxWidth: "1480px",
                height: "fit-content",
                maxHeight: "fit-content",
                marginLeft: "130px",
                borderRadius: "8px",
                "& > div": {
                  minHeight: "100%",
                  "& > div": {
                    padding: "0",
                    height: "100%",
                  },
                },
              },
            }}
          >
            <Box>
              <CollectorMonthView
                year={Number.parseInt(currentFY.substring(0, 4), 10)}
                month={(allMonths.indexOf(monthSelected) + 1).toString()}
                collectorMissingDocuments={collectorMissingDocuments!}
                onClose={() => setOpenMonthView(false)}
              />
            </Box>
          </Dialog>
        </div>
      )}
      {isBillModalOpen && (
        <AddBillModal
          isOpen={isBillModalOpen}
          collectorId={selectedCollectorId}
          onClose={closeBillModal}
          onSaveButtonClick={handleUpdateMissingDocuments}
        />
      )}
    </Box>
  );
};
