import { ALL_MONTHS } from "../consts";

export function completeMonths(
    companyFiscalYear: string[],
    choosenYear: number,
) {
    const allMonths = [...ALL_MONTHS];
    const currentYear: number = Number(choosenYear.toString().substring(2));

    if (companyFiscalYear[0] === "Jan")
        return allMonths.map((month: string) => `${month} '${currentYear}`);

    const currentYearCalendar = allMonths
        .slice(allMonths.indexOf(companyFiscalYear[0]))
        .map((month: string) => `${month} '${currentYear}`);
    const nextYearCalendar = allMonths
        .slice(0, allMonths.indexOf(companyFiscalYear[1]) + 1)
        .map((month: string) => `${month} '${currentYear + 1}`);

    const completeCalendar = [...currentYearCalendar, ...nextYearCalendar];
    return completeCalendar;
}