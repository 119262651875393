import dayjs from "dayjs";

/**
 * Checks if the size of an array matches the number of days in a specific month.
 * @param daysAmount - Size of the days array.
 * @param month - Month to check (1 = January, 2 = February, etc.).
 * @param year - Year of the month (required for leap years).
 * @returns `true` if the array size matches or is bigger than the number of days in the month, `false` if it is smaller.
 */
export const checkDaysMatchMonthLength = (
    daysAmount: number,
    month: number,
    year: number,
): boolean => {
    const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();
    return daysAmount >= daysInMonth;
};
