import React, { ForwardedRef, forwardRef } from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import { COLORS } from "components/consts";
import { EsgArrowDown, DashboardArrowDropdown } from "assets";
import { TextField, TextFieldProps } from "./text-field";

export const EsgAutocompleteField = forwardRef(
    <
        T,
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined,
    >(
        {
            autocompleteProps,
            textFieldProps,
            type,
        }: {
            type?: any;
            textFieldProps: TextFieldProps;
            autocompleteProps: Omit<
                AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
                "renderInput"
            >;
        },
        ref: ForwardedRef<HTMLInputElement>,
    ) => (
        <Autocomplete
            ref={ref}
            size="small"
            fullWidth
            clearIcon={null}
            popupIcon={type === "dashboard" ? <DashboardArrowDropdown /> : <EsgArrowDown />}
            clearOnBlur={false}
            blurOnSelect
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Romance,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Romance,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Romance,
                },
                "& input.MuiInputBase-.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                },
                "& > div > div > div": {
                    height: type === "rounded" ? "34px" : "36px",
                    borderRadius: type === "rounded" ? "8px" : "4px",
                    color: "#354950",
                    "& input": {
                        padding: "0 0 0 8px !important",
                    }
                }
            }}
            slotProps={{
                popper: {
                    sx: {
                        "& .MuiAutocomplete-paper": {
                            border: "1px solid",
                            borderColor: COLORS.Romance,
                            borderRadius: type === "rounded" ? "11px" : "4px",
                        },
                        "& .MuiAutocomplete-option input": {
                            color: COLORS.Romance,
                            fontSize: "16px",
                            borderColor: COLORS.Romance,
                            borderWidth: "1px",
                            minHeight: "26px",
                            borderRadius: type === "rounded" ? "8px" : "4px",
                            transform: type === "rounded" ? "" : "scale(1.4)",
                        },
                        "& .MuiAutocomplete-option input:focus": {
                            borderColor: COLORS.Romance,  // Focus state styling
                        },
                    }
                }
            }}


            renderInput={(params) => (
                <TextField
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            width: type === "dashboard" ? "200px" : "auto",
                            borderRadius: type === "rounded" ? "10px" : type === "dashboard" || type === "checkMissing" ? "8px" : "4px",
                            "& fieldset": {
                                border: type === "dashboard" ? ".5 solid" : "1px solid",
                                borderColor: COLORS.Romance,
                            },
                            "&:hover fieldset": {
                                borderColor: COLORS.Romance,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: COLORS.Romance,
                            },
                        },
                        color: COLORS.Romance
                    }}
                    {...params}
                    {...textFieldProps}
                />
            )}
            {...autocompleteProps}
        />
    ),
);
