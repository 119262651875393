import React from "react";
import { Box } from "@mui/system";

const DataBoxes = ({ color, title }: { color: string; title: string }) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
        }}
    >
        <div
            style={{
                width: "21px",
                height: "21px",
                backgroundColor: color,
                borderRadius: "4px",
            }}
        />
        {title}
    </Box>
);

export default DataBoxes;