import { isNullOrUndefined } from "utils";

import { CellColor } from "../styles/cellStyles";

export const getCellState = ({
  monthUsage,
  billType,
  hasNullUsage,
  enabled = false,
  isBeforeStart,
  isAfterEnd,
  isAfterDisableDate,
  isCurrentCellSelected,
  hasHistoricalData,
  hasMeterOrAccountNumber,
}: {
  monthUsage: number | null;
  billType: string;
  hasNullUsage: boolean;
  enabled?: boolean;
  isBeforeStart: boolean;
  isAfterEnd: boolean;
  isAfterDisableDate: boolean;
  isCurrentCellSelected: boolean;
  hasHistoricalData: boolean;
  hasMeterOrAccountNumber: boolean;
}): { color: CellColor; showModal: boolean } => {
  if (
    !hasHistoricalData ||
    (!hasMeterOrAccountNumber && (monthUsage === 0 || monthUsage === null))
  ) {
    return {
      color: "deactivatedWithData",
      showModal: enabled,
    };
  }

  // Deactivated
  if (isAfterDisableDate || isBeforeStart || isAfterEnd) {
    if (isNullOrUndefined(monthUsage)) {
      return {
        color: "deactivated",
        showModal: false,
      };
    }
    return {
      color: "deactivatedWithData",
      showModal: false,
    };
  }

  // No data
  if (isNullOrUndefined(monthUsage)) {
    return {
      color: "error",
      showModal: true,
    };
  }

  // Partial data
  if (hasNullUsage) {
    return {
      color:
        isCurrentCellSelected
          ? "selected"
          : "warning",
      showModal: false
    };
  }

  // Interpolation - Extrapolation
  if (billType === "INTERPOLATION" || billType === "EXTRAPOLATION") {
    return {
      color:
        isCurrentCellSelected
          ? "selected"
          : "info",
      showModal: false,
    };
  }

  // Default
  return {
    color:
      isCurrentCellSelected
        ? "selected"
        : "none",
    showModal: false,
  };
};
