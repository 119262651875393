import dayjs from "dayjs";

/**
 * Fills an object with all days of a given month, assigning null to missing days.
 * The input object contains day numbers as keys and usage values as values. 
 * @param daysObject - An object where keys are day numbers (as strings) and values are numbers or null.
 * @param month - The month number (1 = January, 2 = February, etc.) to process.
 * @param year - The year of the month (required for accurate day count, e.g., leap years).
 * @returns An object with keys for all days of the month (as two-digit strings, e.g., "01", "02") 
 *          and values from the input object or null for days not present in the input.
 */
export const fillMissingDays = (daysObject: { [key: string]: number | null }, month: number, year: number) => {
    const date = dayjs.utc(`${year}-${month}-01`);
    const daysInMonth = date.daysInMonth();
    const completeDays: { [key: string]: number | null } = {};

    for (let day = 1; day <= daysInMonth; day += 1) {
        const dayStr = day.toString().padStart(2, "0");
        completeDays[dayStr] = dayStr in daysObject ? daysObject[dayStr] : null;
    }

    return completeDays;
};