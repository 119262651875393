import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { UsageDisplay } from "./components/UsageDisplay";
import { getCellState } from "./helpers/getCellState";

import { ALL_MONTHS } from "../../../consts";
import { checkDaysMatchMonthLength } from "../../../helpers/checkDaysMatchMonthLength";
import { DateWrapper } from "./components";

export const MonthCell = ({ cell, handleMonthSelectedOpen, isMonthModalOpen, handleBillModalOpen }: { cell: any, handleMonthSelectedOpen: () => void, handleBillModalOpen: (id: number | null) => void, isMonthModalOpen: boolean }) => {
  const allMonths = ALL_MONTHS;
  const [isCurrentCellSelected, setIsCurrentCellSelected] = useState(false);
  const [year, month] = cell.column.id.split("-").map(Number);
  const monthId = allMonths[month - 1];
  const { billType, monthUsage, days } = cell.row.original.months[monthId] || {
    monthUsage: null,
    billType: "",
    days: [],
  };
  const hasNullUsage =
    days.length > 0 && !checkDaysMatchMonthLength(days.length, month, year);
  const {
    enabled,
    disableDate,
    accountNumber,
    providerName,
    meterNumber,
    startDate,
    endDate,
    id,
  } = cell.row.original.collector;
  const unit = cell.row.original.unit || "";

  const hasHistoricalData = Boolean(
    providerName || accountNumber || meterNumber,
  );
  const hasMeterOrAccountNumber = Boolean(accountNumber || meterNumber);

  const start = dayjs.utc(startDate);
  const end = dayjs.utc(endDate);
  const disable = dayjs.utc(disableDate);
  const current = dayjs.utc(`${year}-${month}-01`);

  const isBeforeStart = current.isBefore(start, "month");
  const isAfterEnd = current.isAfter(end, "month");
  const isAfterDisableDate = current.isAfter(disable, "month");

  const { color, showModal } = getCellState({
    billType,
    monthUsage,
    hasNullUsage,
    enabled,
    isBeforeStart,
    isAfterEnd,
    isAfterDisableDate,
    isCurrentCellSelected,
    hasHistoricalData,
    hasMeterOrAccountNumber,
  });

  const handleDateWrapperClick = () => {
    if (showModal) {
      handleBillModalOpen(id ?? null);
    } else if (monthUsage !== null) {
      handleMonthSelectedOpen();
      setIsCurrentCellSelected(true);
    }
  };

  useEffect(() => {
    if (isCurrentCellSelected && !isMonthModalOpen) setIsCurrentCellSelected(false)
  }, [isMonthModalOpen]) // eslint-disable-line

  return (
    <DateWrapper
      color={color}
      onClick={handleDateWrapperClick}
    >
      <UsageDisplay usage={monthUsage} unit={unit} />
    </DateWrapper>
  );
};
