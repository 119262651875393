/* eslint-disable */
import { Box, Divider } from "@mui/material";
import React, { FunctionComponent } from "react";

import { COLORS, TYPOGRAPHY_COMMON_STYLES } from "components";

import { ColorType } from "types";
import { EsgAutocompleteField } from "./esg-auto-complete-field";
import { EsgArrowDown } from "assets";

export interface MultiSelectControlledOption {
    label: string;
    value: string | number;
}

export const EsgMultiselectControlledField: FunctionComponent<{
    label?: string;
    disabled?: boolean;
    color?: ColorType;
    message?: string;
    selectedValues: MultiSelectControlledOption[];
    options: MultiSelectControlledOption[];
    setSelectedValues: (data: MultiSelectControlledOption[]) => void;
    isAllSelectedOnEmptySelection?: boolean;
    type?: any;
    onchangeFn?: (v: string | number, checked?: boolean) => void;
}> = ({
    label,
    selectedValues,
    options,
    setSelectedValues,
    disabled,
    isAllSelectedOnEmptySelection = false,
    color,
    message,
    type,
    onchangeFn,
}) => {
        const allSelected = selectedValues.length === options.length;
        const isIndeterminate =
            selectedValues.length > 0 && selectedValues.length < options.length;

        const handleSelectAll = (checked: boolean) => {
            setSelectedValues(checked ? options : []);
        };

        return (
            <EsgAutocompleteField
                type={type}
                textFieldProps={{
                    label,
                    disabled,
                    placeholder:
                        isAllSelectedOnEmptySelection && selectedValues.length === 0 ? "All Selected"
                            : selectedValues.length > 0 && type === "checkMissing" ? ""
                                : undefined,
                    message,
                    color,
                }}
                autocompleteProps={{
                    disabled,
                    multiple: true,
                    value: selectedValues,
                    disableClearable: true,
                    blurOnSelect: false,
                    disableCloseOnSelect: true,
                    onChange: (e, data) =>
                        setSelectedValues(data as MultiSelectControlledOption[]),
                    options: [{ label: "Select All", value: "ALL", name: "Select All" }, ...options],
                    isOptionEqualToValue: (opt, val) =>
                        (opt as MultiSelectControlledOption).value ===
                        (val as MultiSelectControlledOption).value,
                    renderTags: (value) => (
                        <Box sx={{ ml: 1 }} component="span">
                            {value.length === 1
                                ? `${value.length} selected`
                                : value.length === options.length
                                    ? "All Selected"
                                    : `${value.length} selected`}
                        </Box>
                    ),
                    renderOption: (props, option: any) => {
                        const isSelected = selectedValues.some(
                            (selected) => selected.value === option.value
                        );

                        return option.value === "ALL" ? (
                            <>
                                <li
                                    {...props}
                                    style={{
                                        color: COLORS.Romance,
                                        fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                        fontSize: 14,
                                        lineHeight: "18px",
                                        backgroundColor: isSelected ? "#F6F8FA" : "white",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onchangeFn && onchangeFn(option.value);
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        checked={allSelected}
                                        ref={(el) => {
                                            if (el) el.indeterminate = isIndeterminate;
                                        }}
                                        onChange={(e) => handleSelectAll(e.target.checked)}
                                        style={{
                                            marginRight: 10,
                                            cursor: "pointer",
                                            accentColor: "#054254",
                                        }}
                                    />
                                    Select All
                                </li>
                                <Divider />
                            </>
                        ) : (
                            <li
                                {...props}
                                style={{
                                    color: COLORS.Romance,
                                    fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    backgroundColor: isSelected ? "#F6F8FA" : "white",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                onClick={(event) => event.stopPropagation()}
                            >
                                <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        onchangeFn && onchangeFn(option.value, isSelected);
                                        setSelectedValues(
                                            e.target.checked
                                                ? [...selectedValues, option]
                                                : selectedValues.filter((s) => s.value !== option.value)
                                        );
                                    }}
                                    style={{
                                        marginRight: 10,
                                        cursor: "pointer",
                                        accentColor: "#054254",
                                    }}
                                />
                                {option.name}
                            </li>
                        );
                    },
                }}
            />
        );
    };
