import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';

import {
    createDataBill, createSaveData,
} from "api";
import { useNotify } from "hooks";
import { QUERY_KEYS } from "consts";
import { getDateFormatmonthset } from "utils";
import { Box, Stack } from "@mui/system";
import { Infounit } from "assets";

interface IModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSaveButtonClick: () => void;
    DayCell?: string;
    collectorId?: any;
}

export const AddBillModal: FC<IModalProps> = ({
    isOpen,
    onClose,
    onSaveButtonClick,
    DayCell,
    collectorId
}) => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const initialFormValues = {
        selectOne: 'Select One',
        fromDate: null,
        toDate: null,
        selectOnetype: 'Select One',
    };
    const [values, setValues] = useState<any>(initialFormValues);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuOpens, setIsMenuOpens] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const handleFromDateChange = (date: any) => {
        setValues({
            ...values,
            fromDate: date,
        });
        validateDates(date, values.toDate);
    };

    const handleToDateChange = (date: any) => {
        setValues({
            ...values,
            toDate: date,
        });
        validateDates(values.fromDate, date);
    };
    // const handleClose = () => {
    //   onClose();
    //   setValues(initialFormValues);
    //   setIsValid(true)
    // };
    const handleClose = async () => {
        await onClose();
        setValues(initialFormValues);
        setIsValid(true);
    };
    const validateDates = (from: number, to: number) => {
        if (from && to && from > to) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    };

    const { mutateAsync: createAddRequest } = useMutation(createDataBill);
    const { mutateAsync: createSaveRequest } = useMutation(createSaveData);
    // const { mutateAsync: updateEstimateRequest } = useMutation(updateEstimate);
    // const { mutateAsync: useCommentsByEstimateId } = useMutation(getCommentsByEstimateId);
    const selectTypes = ["Interpolation", "Extrapolation"];
    const selectTypes1 = ["YoY Change   Calculate usage from historical data taken from previous months/years ",
        "Universal Average   Average of all available current year and past year data points for the collector",
        "Bridging of Months Take the average of the two nearest months on each side"];
    const formatDateToISO = (dateObject: any): string => {
        const inputDate = new Date(dateObject);

        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");

        // return `${year}-${month}-${day}`;
        return `${year}-${month}-${day}T00:00:00Z`;
    };
    const handleSave = async () => {
        const saveData = {
            collectorId: "test Save Data"
        };
        await createSaveRequest(saveData, {
            onError: () => {
                setLoading(false);
                notify.error(`Some error has happened while save data filling!`);
            },
            onSuccess: () => {
                queryClient.invalidateQueries([QUERY_KEYS.MISSING_DOCUMENTS]);
                setLoading(false);
                notify.success(
                    `Data Filling Completed!`,
                );
                onClose();

            },
        });

    }
    const handleAdd = async () => {
        if (
            values.selectOne === 'Select One' ||
            values.selectOnetype === 'Select One' ||
            !values.fromDate ||
            !values.toDate
        ) {
            notify.error('Please fill in all required fields');
            return;
        }
        if (values.fromDate > values.toDate) {
            notify.error('Start date must be before end date');
            return;
        }
        const startDate = new Date(values.fromDate);
        const endDate = new Date(values.toDate);
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        const newStartDate = new Date(startYear, startMonth, 1);
        const newEndDate = new Date(endYear, endMonth + 1, 0);
        const formattedStartDate = formatDateToISO(newStartDate);
        const formattedEndDate = formatDateToISO(newEndDate);
        const year = startDate.getFullYear();
        const calculationTy = values.selectOne.toUpperCase();
        const createRequestData = {
            calculationType: calculationTy,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            calculationMethod: values.selectOnetype,
            year,
            collectorId,
        };
        await createAddRequest(createRequestData, {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onError: (error: any) => {
                setLoading(false);
                let errorMessage = "An error occurred while adding data filling!";
                if (error.response && error.response.data && error.response.data.error) {
                    errorMessage = `An error occurred while adding data filling! ${error.response.data.error}`
                } else if (error.message) {
                    errorMessage = `An error occurred while adding data filling!${error.message}`;
                }
                notify.error(errorMessage);
                // notify.error(`Some error has happened while adding data filling! ${response}`);
                // toast.dismiss();
            },
            onSuccess: async () => {
                setLoading(true);
                onClose();
                try {
                    await queryClient.invalidateQueries([QUERY_KEYS.MISSING_DOCUMENTS]);
                    toast.dismiss();
                    notify.success("Data Filling Completed!");
                    setValues(initialFormValues);
                    onSaveButtonClick();
                } catch (error) {
                    notify.error("Error loading data. Please try again.");
                } finally {
                    setLoading(false);
                }
            },
        });
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const Customnotify = () => (
        <Box sx={{ height: "100%", width: "100%", backgroundColor: "#054254", paddingLeft: '0px' }}>
            <Box sx={{ display: "flex", flexDirection: "column", marginRight: "80px" }} >
                <Typography sx={{ color: "#FFFFFF", ml: 1, fontSize: "18px" }}>
                    Data Filling Has Started
                </Typography>
                <Typography sx={{ color: "#BFDBFE", ml: 1, fontSize: "16px" }}>
                    Your data is currently being filled
                </Typography>
            </Box>
        </Box>

    )

    // eslint-disable-next-line no-lone-blocks, @typescript-eslint/no-unused-expressions
    // { loading && (toast(<Customnotify />, { closeButton: true, hideProgressBar: true, style: { padding: '10px', backgroundColor: "#054254", borderRadius: "10px" }, })) }

    if (loading) {
        toast(<Customnotify />, {
            closeButton: true,
            hideProgressBar: true,
            style: {
                padding: '10px',
                backgroundColor: "#054254",
                borderRadius: "10px"
            }
        });
    }

    const handleSelectChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name || ""]: value,
        });
    };
    const handleSelectOpen = () => {
        setValues((prevValues: any) => ({
            ...prevValues,
            dropdownOpen: true,

        }));
        setIsMenuOpen(true);
    };
    const handleSelectClose = () => {
        setValues((prevValues: any) => ({
            ...prevValues,
            dropdownOpen: false,
        }));
        setIsMenuOpen(false);
    };
    const handleSelectOpens = () => {
        setValues((prevValues: any) => ({
            ...prevValues,
            dropdownOpen: true,

        }));
        setIsMenuOpens(true);
    };
    const handleSelectCloses = () => {
        setValues((prevValues: any) => ({
            ...prevValues,
            dropdownOpen: false,
        }));
        setIsMenuOpens(false);
    };
    const customizeMenuStyles = () => ({
        maxHeight: '450px',
        width: '450x',
        borderradius: '10px',
    });
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                width: "100%",
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "694px",
                        height: DayCell ? "450px" : values.dropdownOpen && isMenuOpen ? "582px" : "510px",
                    },
                },

            }}
            fullWidth
        >
            {
                DayCell ? (
                    <>
                        <DialogTitle
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "-1px",
                                color: "#054254",
                                fontWeight: 600,
                                fontSize: "20px",
                                fontFamily: "Manrope"
                            }}
                        >
                            Data Filling using Interpolation/Extrapolation
                        </DialogTitle>
                        <div
                            style={{
                                borderBottom: "1px solid #D9D9D9",
                                width: "100%",
                            }} />
                        <DialogContent sx={{ overflowY: "hidden", height: "100%", mt: "4px" }}>
                            <Box component="section" mb={3} mt={-1}>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <Stack mb={1} >
                                        <Typography mb={1} sx={{ fontSize: "14px", }}>How would you like to populate your data?</Typography>
                                        <FormControl sx={{ minWidth: 250, width: 280 }}>
                                            <Typography mb={-1} sx={{ fontSize: "16px", color: "#054254", fontWeight: 800, lineHeight: "20px" }}>Interpolation</Typography>
                                        </FormControl>
                                    </Stack>
                                    <Typography mb={1} mt={3} sx={{ fontSize: "14px", }}>Select a date or range that you’d like to apply this to:</Typography>
                                    <FormControl sx={{ minWidth: 250, width: 280 }}>
                                        <Typography mb={-1} sx={{ fontSize: "14px", color: "#054254", fontWeight: 800, lineHeight: "20px" }}>03/01/2023 - 03/15/2023</Typography>
                                    </FormControl>
                                    <Stack mb={1} >
                                        <Typography mb={1} mt={3} sx={{ fontSize: "14px", }}>Please select a calculation method:</Typography>
                                        <FormControl sx={{ minWidth: 250, width: 280 }}>
                                            <Typography mb={1} sx={{ fontSize: "14px", color: "#054254", fontWeight: 800, lineHeight: "20px" }}>YoY Change</Typography>
                                        </FormControl>
                                    </Stack>
                                    <Stack mb={1} spacing={1}>
                                        <Typography
                                            sx={{ fontSize: "14px", }}
                                        >
                                            Interpolated Value Calculated
                                            <Tooltip title={<pre>test tool tip</pre>} placement="top" arrow>
                                                <IconButton
                                                    aria-label="info"
                                                    size="small"
                                                    sx={{ maxHeight: "23px", maxWidth: "23px", mt: 0 }}
                                                >
                                                    <Infounit />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        <FormControl sx={{ minWidth: 250, width: 280 }}>
                                            <Typography mb={1} sx={{ fontSize: "14px", color: "#054254", fontWeight: 800, lineHeight: "20px" }}>425, 703, 522</Typography>
                                        </FormControl>
                                    </Stack>
                                    <Stack mb={1} spacing={2}>
                                        <Typography sx={{ fontSize: "10px", color: "#44853C" }}>Data calculated as of 05/01/2023 09:18:23 UTC</Typography>
                                    </Stack>
                                </form>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ marginBottom: "20px", marginRight: "25px" }}>
                            <Button
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSave}
                                variant="contained"
                                color="primary"
                                sx={{ backgroundColor: "#054254" }}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogTitle
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                                color: "#054254",
                                fontWeight: 600,
                                fontSize: "20px",
                                fontFamily: "Manrope"
                            }}
                        >
                            Data Filling using Interpolation/Extrapolation
                        </DialogTitle>
                        <div
                            style={{
                                borderBottom: "1px solid #D9D9D9",
                                width: "100%",
                            }} />

                        <DialogContent sx={{ overflowY: "hidden" }}>
                            <Box component="section" mb={3} mt={-1}>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <Stack mb={1} spacing={2}>
                                        <Typography mb={-1} sx={{ fontSize: "16px", }}>How would you like to populate your data? </Typography>
                                        <FormControl sx={{ minWidth: 250, width: 280 }}>
                                            <Select
                                                value={values.selectOne}
                                                name="selectOne"
                                                onChange={(e) => handleSelectChange(e)}
                                                onOpen={handleSelectOpens}
                                                onClose={handleSelectCloses}
                                                sx={{
                                                    color: '#054254',
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    borderRadius: "15px",
                                                }}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiPaper-root": {
                                                            borderRadius: "15px",
                                                            mt: 1
                                                        }
                                                    }
                                                }}

                                            >
                                                {isMenuOpens ? null : (
                                                    <MenuItem value="Select One" disabled>
                                                        Select One
                                                    </MenuItem>
                                                )}
                                                {selectTypes.map((option) => (
                                                    <MenuItem key={option} value={option} sx={{ color: "#054254" }}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <Typography mb={2} mt={2} sx={{ fontSize: "16px", }}>Select a date or range that you’d like to apply this to:</Typography>
                                    <Stack direction="row" mb={2} spacing={3}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span style={{ fontSize: "12px" }}>From</span>
                                            <FormControl sx={{ minWidth: "150px", width: "177px", height: "46px" }}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        // label="From"
                                                        onChange={handleFromDateChange}
                                                        format={getDateFormatmonthset()}
                                                        views={['year', 'month']}
                                                        openTo="month"
                                                        sx={{
                                                            "& .MuiInputLabel-root": {
                                                                textAlign: "center",
                                                            },
                                                            "& .MuiInputBase-root": {
                                                                borderRadius: "10px",
                                                                width: "177px",
                                                                height: "46px",
                                                                color: "#054254",
                                                                fontWeight: 600,
                                                            },
                                                        }}
                                                        slotProps={{
                                                            // openPickerIcon:
                                                            inputAdornment: {
                                                                position: 'start',
                                                            },
                                                        }} />
                                                </LocalizationProvider>
                                            </FormControl>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span style={{ fontSize: "12px" }}>To</span>

                                            <FormControl sx={{ minWidth: 150, width: 177 }}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        // label="To"
                                                        onChange={handleToDateChange}
                                                        format={getDateFormatmonthset()}
                                                        views={['year', 'month']}
                                                        openTo="month"
                                                        sx={{
                                                            "& .MuiInputLabel-root": {
                                                                textAlign: "center",
                                                            },
                                                            "& .MuiInputBase-root": isValid ? {
                                                                borderRadius: "10px",
                                                                width: "177px",
                                                                height: "46px",
                                                                color: "#054254",
                                                                fontWeight: 600,
                                                            } : {
                                                                borderRadius: "10px",
                                                                width: "177px",
                                                                height: "46px",
                                                                color: "#054254",
                                                                fontWeight: 600,
                                                                borderColor: 'red',
                                                                borderWidth: "1px",
                                                                borderStyle: "solid",
                                                            },
                                                        }}
                                                        slotProps={{
                                                            inputAdornment: {
                                                                position: 'start',
                                                            },
                                                        }} />
                                                </LocalizationProvider>
                                            </FormControl>

                                        </div>
                                    </Stack>
                                    <Stack mb={1} spacing={2}>
                                        <Typography sx={{ fontSize: "16px", }}>Please select a calculation method:</Typography>
                                        <FormControl sx={{ minWidth: 250, width: values.dropdownOpen && isMenuOpen || values.selectOnetype !== 'Select One' ? 500 : 280 }}>
                                            <Select
                                                value={values.selectOnetype}
                                                name="selectOnetype"
                                                onChange={(e) => handleSelectChange(e)}
                                                onOpen={handleSelectOpen}
                                                onClose={handleSelectClose}
                                                sx={{
                                                    color: '#054254',
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    borderRadius: "15px",
                                                }}
                                                MenuProps={{
                                                    MenuListProps: {
                                                        style: customizeMenuStyles(),
                                                    },
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    },
                                                    transformOrigin: {
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    },
                                                    sx: {
                                                        "&& .Mui-selected": {},
                                                        "& .MuiPaper-root": {
                                                            borderRadius: "15px",
                                                            mt: 2
                                                        }
                                                    }
                                                }}
                                            >
                                                {isMenuOpen ? null : (
                                                    <MenuItem value="Select One" disabled>
                                                        Select One
                                                    </MenuItem>
                                                )}
                                                {selectTypes1.map((option) => {
                                                    const separators = ['YoY Change', 'Universal Average', 'Bridging of Months'];
                                                    let firstPart = option;
                                                    let secondPart = '';
                                                    // eslint-disable-next-line no-restricted-syntax
                                                    for (const separator of separators) {
                                                        const index = firstPart.indexOf(separator);
                                                        if (index !== -1) {
                                                            firstPart = `${option.slice(0, index + separator.length)}`;
                                                            secondPart = option.slice(index + separator.length);
                                                            break;
                                                        }
                                                    }
                                                    return (
                                                        <MenuItem key={option} value={firstPart} sx={{ width: 500 }}>
                                                            <Box>
                                                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                                    <Typography sx={{
                                                                        color: "#054254",
                                                                        fontWeight: 600,
                                                                        width: 200,
                                                                        fontSize: "14px"
                                                                    }}>{firstPart}</Typography>
                                                                    <Box sx={{ width: 250 }}>
                                                                        <Typography sx={{ width: 250, whiteSpace: 'normal', fontSize: "10px" }}>{secondPart}</Typography>

                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </form>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ marginBottom: "20px", marginRight: "15px" }}>
                            <Button
                                onClick={handleClose}
                            // variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleAdd}
                                variant="contained"
                                color="primary"
                                sx={{ backgroundColor: "#054254" }}
                            >
                                Apply
                            </Button>
                        </DialogActions></>
                )
            }
        </Dialog>
    );
};
