/* eslint-disable */
import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Interpolation.css";
import { Viewasofdate } from "assets";
import { Button } from "@mui/material";
import { Tooltip } from "components";

const AddDateModal = ({ viewAsDate, disabled, year }: { viewAsDate: any, disabled: boolean, year: any }) => {

    const [startDate, setStartDate] = useState(new Date());
    const [getDate, setGetDate] = useState();
    const [initialDate] = useState(new Date());
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const CustomTimeInput = () => {
        const handleHourChange = (e: { target: { value: string; }; }) => {
            const hours = parseInt(e.target.value);
            const newDate = new Date(startDate);
            newDate.setHours(hours);
            setStartDate(newDate);
        };

        const handleMinuteChange = (e: { target: { value: string; }; }) => {
            const minutes = parseInt(e.target.value);
            const newDate = new Date(startDate);
            newDate.setMinutes(minutes);
            setStartDate(newDate);
        };

        const handleAmPmChange = (e: { target: { value: any; }; }) => {
            const amPm = e.target.value;
            const newDate = new Date(startDate);
            const hours = newDate.getHours();

            newDate.setHours(hours >= 12 && amPm === "AM" ? hours - 12 : hours + 12);
            setStartDate(newDate);
        };

        return (
            <>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}
                >
                    <select
                        style={{
                            borderRadius: "7px",
                            marginRight: "5px"
                        }}
                        value={startDate.getHours() % 12 || 12}
                        onChange={handleHourChange}>
                        {Array.from({ length: 12 }, (_, index) => (
                            <option key={index} value={index}>
                                {index === 0 ? "12" : index}
                            </option>
                        ))}
                    </select>
                    :
                    <select
                        style={{
                            borderRadius: "7px",
                            marginLeft: "5px"
                        }}
                        value={startDate.getMinutes()}
                        onChange={handleMinuteChange}>
                        {Array.from({ length: 60 }, (_, index) => (
                            <option key={index} value={index}>
                                {index < 10 ? `0${index}` : index}
                            </option>
                        ))}
                    </select>
                    <select
                        style={{
                            borderRadius: "7px",
                            marginLeft: "5px"
                        }}
                        value={startDate.getHours() >= 12 ? "PM" : "AM"}
                        onChange={handleAmPmChange}>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
                <div
                    style={{
                        borderBottom: "1px solid #E5E7EB",
                        width: "100%",
                        margin: "8px 0",
                    }}
                />
                <div style={{
                    marginTop: "10px",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    margin: "5px"
                }}
                >
                    <div>
                        <button
                            style={{
                                cursor: "pointer",
                                height: "35px",
                                width: "75px",
                                margin: "5px",
                                borderRadius: "7px",
                                backgroundColor: "#FFFFFF",
                                color: "#1F2937",
                                border: "1px solid #E5E7EB"
                            }}
                            onClick={() => handleCancel()}>
                            Cancel
                        </button>
                        <button
                            style={{
                                cursor: "pointer",
                                height: "35px",
                                width: "75px",
                                margin: "5px",
                                borderRadius: "7px",
                                backgroundColor: "#054254",
                                color: "#FFFFFF"
                            }}
                            onClick={() => handleApply()}>
                            Apply
                        </button>
                    </div>
                </div>
            </>
        );
    };
    const handleApply = async () => {
        setStartDate(initialDate);
        setDatePickerOpen(false);
        viewAsDate(getDate)
    };
    const handleCancel = () => {
        setStartDate(initialDate);
        setDatePickerOpen(false);
    };
    const handleAmPmChangeAllDate = (e: any) => {
        setStartDate(e);
    };
    // eslint-disable-next-line react/no-unstable-nested-components
    const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: any }>(({ value }, ref) => {
        const buttonFun = (onClick: any) => {
            setDatePickerOpen(true)
            return (
                onClick
            )
        }
        setGetDate(value)

        const showErrorMessage = () => {
            if (disabled) return "Please change toggle to Yearly and choose a date range first"
            if (year === null) return "Please choose a date range first"
            if (!disabled && year !== null) return ""
        }
        return (
            <Tooltip title={showErrorMessage()}>
                <span>
                    <Button
                        variant="contained"
                        style={{
                            color: "#FFFFFF",
                            borderRadius: "6px",
                            fontSize: "",
                            height: "34px",
                            cursor: "pointer"
                        }}
                        startIcon={<Viewasofdate />}
                        className="example-custom-input"
                        disabled={disabled || year === null}
                        onClick={buttonFun} ref={ref}>
                        View as of Date
                    </Button>
                </span>
            </Tooltip>
        )
    });

    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                display: "flex",
            }}>
            <DatePicker
                selected={startDate}
                onChange={(e) => handleAmPmChangeAllDate(e)}
                showTimeInput
                customTimeInput={<CustomTimeInput />}
                timeInputLabel=""
                dateFormat="MM/dd/yyyy h:mm aa"
                open={datePickerOpen}
                onCalendarClose={() => setDatePickerOpen(false)}
                customInput={<ExampleCustomInput value={undefined} />}
                onCalendarOpen={() => setDatePickerOpen(true)}
            />
        </div>
    );
}

export default AddDateModal;