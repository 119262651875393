import React from "react";
import { Box } from "@mui/system";
import { UtilitySpecificEmissionsFactorsIcon, ParameterBasedEstimateIcon, EsgRecIcon } from "assets";
import { EMPTY_CELL_VALUE, UtilityBillTypeKey, UtilityBillType, COLORS, Tooltip } from "components";
import { TABLE_HEADERS } from "consts";

export const ALL_MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const DATA_BOXES = [
  { color: "#FBCBC4", title: "No Data" },
  { color: "#D0EFFD", title: "Extrapolation" },
  { color: "#E4EC81", title: "Partial Data" },
  { color: "#CBF2AC", title: "Complete" },
  { color: "#DDDDDD", title: "Deactivated" }
];

export const MISSING_DOCUMENTS_PAGE = {
  ABSOLUTE_PATH: "/documents/missing",
  PATH: "missing",
  TITLE: "Missing documents",
};

export const MISSING_DOCUMENTS_PAGE_QUERY_PARAMS = {
  COLLECTOR_ID: "collectorId",
  MONTH: "month",
};

export const MISSING_DOCUMENTS_STATIC_COLUMNS = [
  {
    header: "Site",
    accessorKey: "collector.siteName",
    Cell: ({ cell }: { cell: any }) => {
      const value = (cell.getValue() as string) ?? EMPTY_CELL_VALUE;
      return (
        <Tooltip title={value}>
          <Box
            sx={{
              padding: "0 16px",
              textOverflow: "ellipsis",
              maxWidth: "159px",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      );
    },
    enableSorting: false,
    enablePinning: true,
    minSize: 160,
    size: 160,
  },
  {
    header: "Source",
    accessorKey: "collector.providerName",
    Cell: ({ cell }: { cell: any }) => {
      const value = (cell.getValue() as string) ?? EMPTY_CELL_VALUE;
      return (
        <Tooltip title={value}>
          <Box
            sx={{
              padding: "0 16px",
              textOverflow: "ellipsis",
              maxWidth: "179px",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      );
    },
    enableSorting: false,
    enablePinning: true,
    minSize: 180,
    size: 180,
  },
  {
    header: TABLE_HEADERS.accountNumber,
    accessorKey: "collector.accountNumber",
    Cell: ({ cell }: { cell: any }) => {
      const value = (cell.getValue() as string) ?? EMPTY_CELL_VALUE;
      return (
        <Tooltip title={value}>
          <Box
            sx={{
              padding: "0 16px",
              textOverflow: "ellipsis",
              maxWidth: "138px",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      );
    },
    enableSorting: false,
    enablePinning: true,
    minSize: 140,
    size: 140,
  },
  {
    header: TABLE_HEADERS.meterNumber,
    accessorKey: "collector.meterNumber",
    Cell: ({ cell }: { cell: any }) => {
      const value = (cell.getValue() as string) ?? EMPTY_CELL_VALUE;
      return (
        <Tooltip title={value}>
          <Box
            sx={{
              padding: "0 16px",
              textOverflow: "ellipsis",
              maxWidth: "139px",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      );
    },
    enableSorting: false,
    enablePinning: true,
    minSize: 140,
    size: 140,
  },
  {
    header: "State",
    accessorKey: "state",
    Cell: ({ cell }: { cell: any }) => {
      const value = (cell.getValue() as string) ?? EMPTY_CELL_VALUE;
      return (
        <Tooltip title={value}>
          <Box
            sx={{
              padding: "0 16px",
              textOverflow: "ellipsis",
              maxWidth: "139px",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      );
    },
    enableSorting: false,
    enablePinning: true,
    minSize: 140,
    size: 140,
  },
  {
    header: "Type",
    accessorKey: "collector.utilityTypeName",
    Cell: ({ cell }: { cell: any }) => {
      const value =
        (cell.getValue() as UtilityBillTypeKey) ?? EMPTY_CELL_VALUE;
      return (
        <Box
          sx={{
            padding: "0 16px",
            textOverflow: "ellipsis",
            maxWidth: "139px",
          }}
        >
          <UtilityBillType type={value} />
        </Box>
      );
    },
    enableSorting: false,
    minSize: 140,
    size: 140,
  },
  {
    accessorKey: "collector.estimate",
    header: "Tags",
    Cell: ({ cell }: { cell: any }) => {
      const showEstimateIndicator = cell.row.original.collector.estimate;
      const showSpendBasedIndicator = cell.row?.original?.spendBased;
      const showRecIndicator = cell.row?.original?.collector?.recs;

      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            height: "24px",
            padding: "0 16px",
          }}
        >
          {showSpendBasedIndicator ? (
            <Tooltip title="There are Spend Based Estimations at this site">
              <UtilitySpecificEmissionsFactorsIcon color={COLORS.Mainblue} />
            </Tooltip>
          ) : (
            <Box sx={{ width: "24px", height: "24px" }} />
          )}
          {showEstimateIndicator ? (
            <Tooltip title="There are Parameter Based Estimations at this site">
              <ParameterBasedEstimateIcon color={COLORS.Mainblue} />
            </Tooltip>
          ) : (
            <Box sx={{ width: "24px", height: "24px" }} />
          )}
          {showRecIndicator ? (
            <Tooltip title="This site has a REC applied to its emissions">
              <EsgRecIcon color={COLORS.Mainblue} />
            </Tooltip>
          ) : (
            <Box sx={{ width: "24px", height: "24px" }} />
          )}
        </Box>
      );
    },
    enableSorting: false,
    minSize: 150,
    size: 150,
  },
];