import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { EsgArrowDown } from "assets";
import { COLORS } from "components";

const SelectField = ({
    options,
    initialValue,
    onChangeFn,
    placeholder,
}: {
    options: string[] | number[];
    initialValue: string | number;
    onChangeFn: React.Dispatch<React.SetStateAction<any>>;
    placeholder: string;
}) => (
    <FormControl sx={{ width: "200px" }}>
        <Select
            displayEmpty
            sx={{
                height: "36px",
                borderRadius: "8px",
                fontWeight: 500,
                color: COLORS.Mainblue,
                "& > fieldset": {
                    borderColor: COLORS.Mainblue,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.Mainblue,
                    borderWidth: "1px",
                },
                "& svg": {
                    margin: "5px 3px",
                },
            }}
            IconComponent={EsgArrowDown}
            value={initialValue ?? ""}
            onChange={(event) => onChangeFn(event.target.value as string)}
            renderValue={(selected) => {
                if (!selected) {
                    return (
                        <p style={{ color: COLORS.Mainblue, margin: 0 }}>{placeholder}</p>
                    );
                }
                return selected;
            }}
        >
            {options &&
                options.map((option: string | number) => (
                    <MenuItem
                        key={option}
                        value={option}
                        sx={{ color: COLORS.Mainblue, fontWeight: 500 }}
                    >
                        {option}
                    </MenuItem>
                ))}
        </Select>
    </FormControl>
);

export default SelectField;